import { forwardRef, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SearchIcon from "../icons/SearchIcon";
import { SearchInner, SearchWrapper, StyledSearchInput } from "./CommonDropdown.styled";

type Props = {
  searchTerm: string;
  placeholder: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  onSearchCallback?: (searchTerm: string) => void;
  startSearchAnalytics?: (searchTerm: string) => void;
  label?: string;
  ariaControl?: string;
  className?: string;
};

export default forwardRef(function SearchInput(
  {
    searchTerm,
    setSearchTerm,
    onSearchCallback,
    startSearchAnalytics,
    placeholder,
    label,
    className,
    ariaControl,
  }: Props,
  ref: any
) {
  const theLabel = useRef<string>();
  const isDesktop = useMediaQuery({
    minWidth: 768,
  });
  const [isSearchAnalyticsFired, setIsSearchAnalyticsFired] = useState(false);

  useEffect(() => {
    let aLabel = theLabel.current;
    if (label !== aLabel) {
      theLabel.current === label;
      setIsSearchAnalyticsFired(false);
    }
  }, [label]);

  return (
    <SearchWrapper role="search" tabIndex={!isDesktop && 0} className={className}>
      <form onSubmit={(e) => e.preventDefault()}>
        <SearchInner htmlFor="searchInput">
          <SearchIcon />
          <StyledSearchInput
            data-cy={label}
            value={searchTerm}
            role="combobox"
            aria-controls={ariaControl}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (onSearchCallback) onSearchCallback(e.target.value || "");
              if (startSearchAnalytics && !isSearchAnalyticsFired) {
                setIsSearchAnalyticsFired(true);
                startSearchAnalytics(e.target.value || "");
              }
            }}
            id="searchInput"
            placeholder={placeholder}
            ref={ref}
          />
        </SearchInner>
      </form>
    </SearchWrapper>
  );
});
