import styled from "styled-components";

export const PhoneNumberWrapper = styled.div`
  direction: ltr;
  position: relative;
  display: flex;
`;
export const StyledPhoneInput = styled.input`
  && {
    font-size: 16px;
    transition: 0.2s ease border-color;
    outline: none;
    border: none;
    display: block;
    text-align: left;
    width: 100%;
    padding: 7px 0 7px 50px;
    border-bottom: #a1a3a6 1px solid;
    color: #555555;
    font-weight: 600;
    border-radius: 0;
    &:hover {
      border-color: #282828;
    }
    &:focus {
      border-color: #1a99d6;
    }
    &::placeholder {
      color: #a2a3a6;
    }
    &:disabled {
      background-color: #fff;
      cursor: not-allowed;
    }
  }
`;
export const CountryTrigger = styled.button<{
  readonly isOpen?: boolean;
}>`
  background: ${({ isOpen }) => (isOpen ? "#EDEDED" : "transparent")};
  border: none;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* gap: 4px; */
  padding-left: 5px;
  z-index: 1;
  height: 28px;
  width: 44px;
  border-radius: 2px;
  svg {
    min-width: 14px;
  }
  &:disabled {
    cursor: not-allowed !important;
  }
`;
export const StyledFlag = styled.span`
  min-width: 22px;
  max-width: 22px;
  min-height: 15.33px;
  line-height: 0;
  overflow: hidden;
  border-radius: 2px;
  border: 1px solid #e7e9ef;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const PhoneCountryDropdownWrapper = styled.div`
  background-color: white;
  font-size: 16px;
  text-align: left;
  overflow: hidden;
  width: 100%;
  max-width: calc(100vw - 20px);
  z-index: 10;
  position: relative;
  box-shadow: 0px 3px 6px 0px #55555526;
  border: 1px solid #e7e9ef;
  border-radius: 5px;
  outline: none;
  .countries-search-input {
    margin: 0 !important;
    label {
      height: 45px;
    }
  }
`;
export const UlCountries = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 !important;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 192px;
`;
export const LiCountry = styled.li<{
  readonly isSelected?: boolean;
  readonly isDisabled?: boolean;
}>`
  font-size: 1.4rem !important;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.textDark};
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  cursor: pointer;
  transition: 0.2s ease background-color;
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.white200 : theme.colors.white)};
  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.colors.white200};
  }
  display: flex;
  align-items: center;
  gap: 10px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "auto")};
  span {
    color: #999;
    min-width: 22px;
  }
`;
export const EmptyMsg = styled.div`
  color: #a1a3a6;
  padding: 10px;
  text-align: center;
`;
