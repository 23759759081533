import useTranslation from "@/intl/useTranslation";
import { ChevronDown, ChevronUp } from "lucide-react";
import { ElementRef, forwardRef, useRef, useState } from "react";
import { mergeRefs } from "react-merge-refs";
import * as RPNInput from "react-phone-number-input";
import RPNInputComponent from "react-phone-number-input/input";
import { useEffectOnce } from "react-use";
import PhoneCountriesDropdown from "./PhoneCountriesDropdown";
import { CountryTrigger, PhoneNumberWrapper, StyledFlag, StyledPhoneInput } from "./PhoneInput.styled";

type PhoneInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange" | "value"> &
  Omit<RPNInput.Props<typeof RPNInput.default>, "onChange"> & {
    onChange?: (value: RPNInput.Value) => void;
  };

export const FlagComponent = ({ country, countryName }: RPNInput.FlagProps) => {
  return (
    <span>
      <img
        loading="lazy"
        alt={countryName}
        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`}
      />
    </span>
  );
};
FlagComponent.displayName = "FlagComponent";

const PhoneInput: React.ForwardRefExoticComponent<PhoneInputProps> = forwardRef<
  ElementRef<typeof RPNInput.default>,
  PhoneInputProps
>(({ onChange, disabled, defaultCountry, ...props }, ref) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<RPNInput.Value>();
  const [country, setCountry] = useState<RPNInput.Country>(defaultCountry);
  const localInputRef = useRef(null);

  const onPhoneNumberChange = (number: RPNInput.Value) => {
    if (number) {
      const parsedNumber = RPNInput.parsePhoneNumber(number, country);
      if (number.startsWith("+")) {
        if (parsedNumber && parsedNumber.country !== country) {
          onCountryChange(parsedNumber.country);
        }
      }
    }
    setValue(number || "");
    onChange && onChange(number || "");
  };

  useEffectOnce(() => {
    setValue(props.value);
  });

  const onCountryChange = (country: RPNInput.Country) => {
    setCountry(country);
    setValue("");
    setTimeout(() => {
      localInputRef.current.focus();
    }, 10);
  };

  return (
    <PhoneNumberWrapper>
      <PhoneCountriesDropdown
        value={country}
        setValue={onCountryChange}
        searchPlaceholder={t("Search Country")}
        triggerer={({ open, value, ...props }) => (
          <CountryTrigger type="button" isOpen={!disabled && open} {...props} disabled={disabled}>
            <StyledFlag>
              <FlagComponent country={value} countryName={value} />
            </StyledFlag>
            {open ? <ChevronUp color="#000" size={14} /> : <ChevronDown color="#000" size={14} />}
          </CountryTrigger>
        )}
      />
      <RPNInputComponent
        {...props}
        ref={mergeRefs([ref, localInputRef])}
        useNationalFormatForDefaultCountryValue={false}
        inputComponent={StyledPhoneInput}
        disabled={disabled}
        onChange={onPhoneNumberChange}
        defaultCountry={country}
        value={value}
      />
    </PhoneNumberWrapper>
  );
});

PhoneInput.displayName = "PhoneInput";

const { isValidPhoneNumber, parsePhoneNumber } = RPNInput;
export { PhoneInput, isValidPhoneNumber, parsePhoneNumber };
