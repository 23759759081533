import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

export const CommonSelectButton = styled.button<{
  readonly isError?: boolean;
}>`
  appearance: none !important;
  border: none !important;
  outline: none !important;
  position: relative;
  display: flex !important;
  align-items: center !important;
  gap: 7px !important;
  width: 100% !important;
  max-width: 354px;
  min-height: 67px;
  max-height: 67px;
  padding: 4px 8.5px !important;
  font-size: 1.6rem;
  text-align: left;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: ${({ theme }) => theme.colors.textDark};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  padding: 10px 15px !important;
  transition: 0.3s ease box-shadow;
  ${({ isError, theme }) =>
    isError &&
    `
    box-shadow: 0px 0px 0px 2px ${theme.colors.system.red} inset;
  `}
  ${media.max.sm`
    max-width: none;
  `}
`;
export const CommonSelectButtonData = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
`;
export const CommonSelectTitle = styled.div`
  font-size: 1.6rem !important;
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  line-height: 1.5;
`;
export const CommonSelectValue = styled.div<{
  readonly isOptionSelected?: boolean;
}>`
  font-size: 1.4rem !important;
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  line-height: 1.5;
  color: ${({ theme, isOptionSelected }) => (isOptionSelected ? theme.colors.primaryPurple : theme.colors.textGray)};
  transition: 0.3s ease color;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const StyledOption = styled.li<{
  readonly isSelected?: boolean;
  readonly isDisabled?: boolean;
}>`
  font-size: 1.6rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.textDark};
  padding: 12px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  cursor: pointer;
  transition: 0.2s ease background-color;
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.white200 : theme.colors.white)};
  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.colors.white200};
  }
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "auto")};
`;
export const StyledUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 !important;
  flex: 1;
  overflow-y: auto;
`;
export const DropdownWrapper = styled.div<{
  readonly customListHeight?: number | null;
}>`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  width: 400px !important;
  height: auto !important;
  display: flex;
  flex-direction: column;
  max-height: ${({ customListHeight }) => (customListHeight ? customListHeight : 300)}px !important;
  z-index: 13;
  overflow: hidden;
  ${media.max.sm`
    width: calc(100% - 30px) !important;
  `}
`;
export const SearchWrapper = styled.section``;
export const SearchInner = styled.label`
  display: flex !important;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 0 10px;
  margin-bottom: 0 !important;
  width: 100%;
`;
export const StyledSearchInput = styled.input`
  border: none;
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 1.6rem !important;
  margin-left: 10px !important;
  font-weight: 500 !important;
`;
export const TabsWrapper = styled.nav<{
  readonly selectedTab: "city" | "area";
}>`
  min-height: 45px;
  max-height: 45px;
  display: flex !important;
  align-items: center;
  border-bottom: 1px solid #e7e9ef;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateX(${({ selectedTab }) => (selectedTab === "city" ? 0 : "100%")});
    width: 50%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.textDark};
    transition: 0.3s ease transform;
  }
`;
export const TabButton = styled.a<{
  readonly isActive?: boolean;
}>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.textDark : theme.colors.textGray)} !important;
  text-decoration: none !important;
  cursor: pointer;
  transition: 0.3s ease color;
`;

export const DoctorOption = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const DoctorOptionText = styled.p`
  margin: 0px !important;
`;
export const DoctorOptionCount = styled.p<{
  readonly isDisabled?: boolean;
}>`
  margin: 0px !important;
  font-weight: ${({ theme }) => theme.fontWeight.font400} !important;
  font-size: ${({ theme }) => theme.fontsizes.body16} !important;
  color: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.textGray : theme.colors.primaryPurple)} !important;
`;
