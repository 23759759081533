import useTranslation from "@/intl/useTranslation";
import {
  autoUpdate,
  ContextData,
  flip,
  FloatingFocusManager,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from "@floating-ui/react-dom-interactions";
import debounce from "lodash/debounce";
import { useRouter } from "next/router";
import { createContext, Dispatch, memo, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react";
import * as RPNInput from "react-phone-number-input";
import ar from "react-phone-number-input/locale/ar.json";
import en from "react-phone-number-input/locale/en.json";
import { useMediaQuery } from "react-responsive";
import { useIsomorphicLayoutEffect } from "react-use";
import SearchInput from "../HomeHero/CommonDropdown/SearchInput";
import { FlagComponent } from "./PhoneInput";
import { EmptyMsg, LiCountry, PhoneCountryDropdownWrapper, UlCountries } from "./PhoneInput.styled";

interface Props {
  value?: RPNInput.Country;
  setValue: (country: RPNInput.Country) => void;
  searchPlaceholder?: string;
  triggerer: any;
  onSearchInput?: (searchTerm: string) => void;

  // onToggleDropdown?: (isOpened: boolean, type: "City" | "Area") => void;
  // onSelectOption?: (val: CountryValue, type: "City" | "Area") => void;
  // startSearchAnalytics?: (searchTerm: string, type: "City" | "Area") => void;
  // position?: string;
  // left?: number;
  // hideAllKeywordOption?: boolean;
}

interface SelectContext {
  activeIndex: number | null;
  setActiveIndex: (index: number | null) => void;
  value: RPNInput.Country;
  setValue: Dispatch<SetStateAction<RPNInput.Country>>;
  listRef: React.MutableRefObject<Array<HTMLLIElement | null>>;
  setOpen: (open: boolean) => void;
  getItemProps: (userProps?: React.HTMLProps<HTMLElement>) => any;
  dataRef: ContextData;
  afterSelectCallback: (value: RPNInput.Country) => void;
}
const TheSelectContext = createContext({} as SelectContext);

export const TheOption: React.FC<{
  optionCode: string;
  optionLabel: string;
  optionValue: RPNInput.Country;
  index: number;
}> = memo(({ index = 0, optionCode, optionLabel, optionValue }) => {
  const { listRef, setActiveIndex, afterSelectCallback, value, setValue, activeIndex, getItemProps, dataRef } =
    useContext(TheSelectContext);

  const handleSelect = () => {
    setValue(optionValue);
    setActiveIndex(null);
    afterSelectCallback(optionValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSelect();
    }

    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === " " && !dataRef.current.typing) {
      handleSelect();
    }
  };

  return (
    <LiCountry
      role="option"
      ref={(node) => (listRef.current[index] = node)}
      tabIndex={activeIndex === index ? 0 : 1}
      aria-selected={activeIndex === index}
      data-selected={value === optionValue}
      isSelected={value === optionValue}
      data-cy={optionValue || ""}
      {...getItemProps({
        onClick: handleSelect,
        onKeyDown: handleKeyDown,
        onKeyUp: handleKeyUp,
      })}
    >
      <FlagComponent country={optionValue} countryName={optionLabel} />
      {optionLabel}
      <span dir="ltr">+{optionCode}</span>
    </LiCountry>
  );
});

export default function PhoneCountriesDropdown({
  triggerer,
  value,
  setValue,
  onSearchInput,
  searchPlaceholder,
}: Props) {
  const { locale } = useRouter();
  const { t } = useTranslation();
  const allCountries = useMemo(
    () =>
      RPNInput.getCountries().map((country) => ({
        label: locale === "ar" ? ar[country] : en[country],
        value: country,
        country_code: RPNInput.getCountryCallingCode(country),
        label_en: en[country],
      })),
    []
  );

  const isDesktop = useMediaQuery({
    minWidth: 768,
  });
  const listItemsRef = useRef<Array<HTMLLIElement | null>>([]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [pointer, setPointer] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const searchInputRef = useRef(null);
  // const [value, setValue] = useState(originalValue);
  const [isFirstRender, setIsFirstRender] = useState(false);
  const options = useMemo(() => {
    const theSearchTerm = searchTerm?.toLocaleLowerCase();
    return theSearchTerm
      ? [
          ...allCountries.filter(
            (country) =>
              country.label.toLocaleLowerCase().includes(theSearchTerm) ||
              country.label_en.toLocaleLowerCase().includes(theSearchTerm) ||
              country.country_code.includes(theSearchTerm) ||
              country.value.includes(theSearchTerm)
          ),
        ]
      : allCountries;
  }, [searchTerm]);

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: () => {
      setOpen((prev) => !prev);
      setSearchTerm("");
    },
    whileElementsMounted: autoUpdate,
    placement: "bottom-start",
    middleware: [
      offset(10),
      shift(),
      flip(),
      size({
        apply({ elements }) {
          Object.assign(elements.floating.style, {
            maxHeight: `300px`,
            width: `100%`,
          });
        },
      }),
    ],
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useClick(context),
    useRole(context, { role: "listbox" }),
    useDismiss(context),
    useListNavigation(context, {
      listRef: listItemsRef,
      activeIndex,
      onNavigate: setActiveIndex,
    }),
  ]);

  // Scroll the active or selected item into view when in `controlledScrolling`
  // mode (i.e. arrow key nav).
  useIsomorphicLayoutEffect(() => {
    if (open && activeIndex != null && !pointer) {
      requestAnimationFrame(() => {
        listItemsRef.current[activeIndex]?.scrollIntoView({
          block: "nearest",
        });
      });
    }
  }, [open, activeIndex, pointer]);

  useEffect(() => {
    if (open && searchInputRef?.current && isDesktop) {
      setTimeout(() => {
        searchInputRef?.current?.focus();
      }, 300);
    }
  }, [open]);

  const afterSelectCallback = (value: RPNInput.Country) => {
    setSearchTerm("");
    setOpen(false);
  };

  // useEffect(() => {
  //   if (isFirstRender) {
  //     setIsFirstRender(false);
  //     return;
  //   }

  //   setOriginalValue(value);
  // }, [value]);

  const _debouncedOnSearchInput = useMemo(() => (onSearchInput ? debounce(onSearchInput, 500) : null), []);

  const initialFocus = useMemo(() => options.findIndex((op) => op.value === value) + 1, [open]);

  return (
    <TheSelectContext.Provider
      value={{
        activeIndex,
        setActiveIndex,
        value: value,
        afterSelectCallback,
        setValue,
        listRef: listItemsRef,
        setOpen,
        getItemProps,
        dataRef: context.dataRef,
      }}
    >
      {triggerer({
        "data-cy": "country-trigger",
        open,
        value: value,
        ...getReferenceProps({
          ref: reference,
          style: open
            ? {
                zIndex: 11,
              }
            : {},
        }),
      })}
      {open && (
        <FloatingFocusManager context={context} initialFocus={initialFocus}>
          <PhoneCountryDropdownWrapper
            dir={locale === "ar" ? "rtl" : "ltr"}
            data-cy="phone-country-dropdown"
            {...getFloatingProps({
              ref: floating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              },
              onPointerMove() {
                setPointer(true);
              },
              onKeyDown(event) {
                setPointer(false);
                if (event.key === "Tab") {
                  setOpen(false);
                  return;
                }
                if (event.key === "Enter") {
                  setOpen(false);

                  return;
                }
                searchInputRef.current.focus();
              },
            })}
          >
            <SearchInput
              label={`countries-dropdown-search-input`}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              placeholder={searchPlaceholder}
              ref={searchInputRef}
              ariaControl="country-dropdown-list"
              className="countries-search-input"
              onSearchCallback={(searchTerm) => {
                if (onSearchInput) _debouncedOnSearchInput(searchTerm);
              }}
            />
            <UlCountries
              key={value}
              role="group"
              id="country-dropdown-list"
              aria-labelledby={`floating-ui-select-${value}`}
              data-cy="country-dropdown-list"
            >
              {options.length > 0 ? (
                options.map((option, index) => {
                  return (
                    <TheOption
                      key={option.value}
                      index={index}
                      optionCode={option.country_code}
                      optionLabel={option.label}
                      optionValue={option.value}
                    />
                  );
                })
              ) : (
                <div>
                  <EmptyMsg>{t("Country not found")}</EmptyMsg>
                </div>
              )}
            </UlCountries>
          </PhoneCountryDropdownWrapper>
        </FloatingFocusManager>
      )}
    </TheSelectContext.Provider>
  );
}
